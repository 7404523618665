import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _29f09dda = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _504f09fc = () => interopDefault(import('../pages/affiliate-partner.vue' /* webpackChunkName: "pages/affiliate-partner" */))
const _1ebe6a39 = () => interopDefault(import('../pages/aml-policies.vue' /* webpackChunkName: "pages/aml-policies" */))
const _3931f204 = () => interopDefault(import('../pages/bets.vue' /* webpackChunkName: "pages/bets" */))
const _5b8f679a = () => interopDefault(import('../pages/blockuserip.vue' /* webpackChunkName: "pages/blockuserip" */))
const _aae7f910 = () => interopDefault(import('../pages/blog-old.vue' /* webpackChunkName: "pages/blog-old" */))
const _688cf58a = () => interopDefault(import('../pages/book-register.vue' /* webpackChunkName: "pages/book-register" */))
const _2e1f9f98 = () => interopDefault(import('../pages/casino/index.vue' /* webpackChunkName: "pages/casino/index" */))
const _4a97d60e = () => interopDefault(import('../pages/casino-old/index.vue' /* webpackChunkName: "pages/casino-old/index" */))
const _38e2119c = () => interopDefault(import('../pages/casino-slots/index.vue' /* webpackChunkName: "pages/casino-slots/index" */))
const _69a27fd8 = () => interopDefault(import('../pages/change-mobileno.vue' /* webpackChunkName: "pages/change-mobileno" */))
const _4f3260cc = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _0356af06 = () => interopDefault(import('../pages/check-in.vue' /* webpackChunkName: "pages/check-in" */))
const _731198e7 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _144c3406 = () => interopDefault(import('../pages/dglp/index.vue' /* webpackChunkName: "pages/dglp/index" */))
const _6db436aa = () => interopDefault(import('../pages/enroll-now.vue' /* webpackChunkName: "pages/enroll-now" */))
const _260591a4 = () => interopDefault(import('../pages/fantasy-pro-rules.vue' /* webpackChunkName: "pages/fantasy-pro-rules" */))
const _78462af4 = () => interopDefault(import('../pages/fantasybook/index.vue' /* webpackChunkName: "pages/fantasybook/index" */))
const _ab9778ce = () => interopDefault(import('../pages/faqs.vue' /* webpackChunkName: "pages/faqs" */))
const _596c5ea0 = () => interopDefault(import('../pages/fast-signup/index.vue' /* webpackChunkName: "pages/fast-signup/index" */))
const _16afeeea = () => interopDefault(import('../pages/favorites.vue' /* webpackChunkName: "pages/favorites" */))
const _74e45790 = () => interopDefault(import('../pages/finsa/index.vue' /* webpackChunkName: "pages/finsa/index" */))
const _53538439 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _cf5692f8 = () => interopDefault(import('../pages/gift-card/index.vue' /* webpackChunkName: "pages/gift-card/index" */))
const _61273b56 = () => interopDefault(import('../pages/inplay.vue' /* webpackChunkName: "pages/inplay" */))
const _636c58da = () => interopDefault(import('../pages/line-market-rules.vue' /* webpackChunkName: "pages/line-market-rules" */))
const _d872bd7e = () => interopDefault(import('../pages/live-cards/index.vue' /* webpackChunkName: "pages/live-cards/index" */))
const _01a0a2ad = () => interopDefault(import('../pages/live-casino/index.vue' /* webpackChunkName: "pages/live-casino/index" */))
const _5a56cf1f = () => interopDefault(import('../pages/live-games/index.vue' /* webpackChunkName: "pages/live-games/index" */))
const _b4d4bac6 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _5ba22c0c = () => interopDefault(import('../pages/loyalty.vue' /* webpackChunkName: "pages/loyalty" */))
const _4bd57c47 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _2a3de492 = () => interopDefault(import('../pages/my-markets.vue' /* webpackChunkName: "pages/my-markets" */))
const _74199ab0 = () => interopDefault(import('../pages/new-pending-bonus.vue' /* webpackChunkName: "pages/new-pending-bonus" */))
const _4be17a6a = () => interopDefault(import('../pages/new-pending-sportbonus.vue' /* webpackChunkName: "pages/new-pending-sportbonus" */))
const _71d8a962 = () => interopDefault(import('../pages/news.vue' /* webpackChunkName: "pages/news" */))
const _4a8ed6c8 = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _1f6c57a8 = () => interopDefault(import('../pages/online-betting-app1.vue' /* webpackChunkName: "pages/online-betting-app1" */))
const _afe770c6 = () => interopDefault(import('../pages/pending-bonus.vue' /* webpackChunkName: "pages/pending-bonus" */))
const _fe818a54 = () => interopDefault(import('../pages/playnow.vue' /* webpackChunkName: "pages/playnow" */))
const _13830a35 = () => interopDefault(import('../pages/premium-bookmaker-fancy.vue' /* webpackChunkName: "pages/premium-bookmaker-fancy" */))
const _f8abbf4e = () => interopDefault(import('../pages/premium-cricket-rules.vue' /* webpackChunkName: "pages/premium-cricket-rules" */))
const _59e8e9c6 = () => interopDefault(import('../pages/premium-sportsbook/index.vue' /* webpackChunkName: "pages/premium-sportsbook/index" */))
const _c217a2f8 = () => interopDefault(import('../pages/premium-sportsbook-rules.vue' /* webpackChunkName: "pages/premium-sportsbook-rules" */))
const _dc6e56da = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _dc377446 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _03991043 = () => interopDefault(import('../pages/profit-loss/index.vue' /* webpackChunkName: "pages/profit-loss/index" */))
const _0f3eced6 = () => interopDefault(import('../pages/profit-loss-new/index.vue' /* webpackChunkName: "pages/profit-loss-new/index" */))
const _019aa72c = () => interopDefault(import('../pages/promotions.vue' /* webpackChunkName: "pages/promotions" */))
const _7bbe6181 = () => interopDefault(import('../pages/quick-checkin.vue' /* webpackChunkName: "pages/quick-checkin" */))
const _8464a8fc = () => interopDefault(import('../pages/quick-enroll.vue' /* webpackChunkName: "pages/quick-enroll" */))
const _2273333d = () => interopDefault(import('../pages/quick-joinus1.vue' /* webpackChunkName: "pages/quick-joinus1" */))
const _22814abe = () => interopDefault(import('../pages/quick-joinus2.vue' /* webpackChunkName: "pages/quick-joinus2" */))
const _0bfe8730 = () => interopDefault(import('../pages/quick-reg.vue' /* webpackChunkName: "pages/quick-reg" */))
const _462e79fa = () => interopDefault(import('../pages/quick-reg2.vue' /* webpackChunkName: "pages/quick-reg2" */))
const _965ffb12 = () => interopDefault(import('../pages/quick-signup/index.vue' /* webpackChunkName: "pages/quick-signup/index" */))
const _65d48719 = () => interopDefault(import('../pages/quick-signup-2.vue' /* webpackChunkName: "pages/quick-signup-2" */))
const _771d6d6d = () => interopDefault(import('../pages/quick-signup1.vue' /* webpackChunkName: "pages/quick-signup1" */))
const _772b84ee = () => interopDefault(import('../pages/quick-signup2.vue' /* webpackChunkName: "pages/quick-signup2" */))
const _77399c6f = () => interopDefault(import('../pages/quick-signup3.vue' /* webpackChunkName: "pages/quick-signup3" */))
const _7747b3f0 = () => interopDefault(import('../pages/quick-signup4.vue' /* webpackChunkName: "pages/quick-signup4" */))
const _5b7a004d = () => interopDefault(import('../pages/racing/index.vue' /* webpackChunkName: "pages/racing/index" */))
const _3f5255ce = () => interopDefault(import('../pages/referral.vue' /* webpackChunkName: "pages/referral" */))
const _403e8720 = () => interopDefault(import('../pages/region-block.vue' /* webpackChunkName: "pages/region-block" */))
const _02f0d5c2 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _5459fb96 = () => interopDefault(import('../pages/registration.vue' /* webpackChunkName: "pages/registration" */))
const _36b62466 = () => interopDefault(import('../pages/registration2.vue' /* webpackChunkName: "pages/registration2" */))
const _3699f564 = () => interopDefault(import('../pages/registration3.vue' /* webpackChunkName: "pages/registration3" */))
const _41e629d5 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _44a38958 = () => interopDefault(import('../pages/responsible-gambling.vue' /* webpackChunkName: "pages/responsible-gambling" */))
const _34479e67 = () => interopDefault(import('../pages/rules-regulations.vue' /* webpackChunkName: "pages/rules-regulations" */))
const _f7a93ac2 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _0e3dfa2e = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _327ba967 = () => interopDefault(import('../pages/sportsbook/index.vue' /* webpackChunkName: "pages/sportsbook/index" */))
const _4f707952 = () => interopDefault(import('../pages/store-profit-loss/index.vue' /* webpackChunkName: "pages/store-profit-loss/index" */))
const _4fbde356 = () => interopDefault(import('../pages/survey.vue' /* webpackChunkName: "pages/survey" */))
const _45b828a4 = () => interopDefault(import('../pages/termsandconditions.vue' /* webpackChunkName: "pages/termsandconditions" */))
const _5a320b1e = () => interopDefault(import('../pages/thankyou.vue' /* webpackChunkName: "pages/thankyou" */))
const _960ba552 = () => interopDefault(import('../pages/time-settings.vue' /* webpackChunkName: "pages/time-settings" */))
const _20e2165e = () => interopDefault(import('../pages/transactions.vue' /* webpackChunkName: "pages/transactions" */))
const _1ec423ba = () => interopDefault(import('../pages/transfer-statements.vue' /* webpackChunkName: "pages/transfer-statements" */))
const _2a2900e6 = () => interopDefault(import('../pages/transfer-statements-new.vue' /* webpackChunkName: "pages/transfer-statements-new" */))
const _e2751fee = () => interopDefault(import('../pages/tutorials.vue' /* webpackChunkName: "pages/tutorials" */))
const _a5f42842 = () => interopDefault(import('../pages/user-book.vue' /* webpackChunkName: "pages/user-book" */))
const _816318a2 = () => interopDefault(import('../pages/user-registration.vue' /* webpackChunkName: "pages/user-registration" */))
const _2ee6a5d0 = () => interopDefault(import('../pages/wac/index.vue' /* webpackChunkName: "pages/wac/index" */))
const _6bf27990 = () => interopDefault(import('../pages/wallet/index.vue' /* webpackChunkName: "pages/wallet/index" */))
const _113fd432 = () => interopDefault(import('../pages/casino-old/live-casino.vue' /* webpackChunkName: "pages/casino-old/live-casino" */))
const _17f09c4a = () => interopDefault(import('../pages/casino/casino-list/index.vue' /* webpackChunkName: "pages/casino/casino-list/index" */))
const _55c9aaa3 = () => interopDefault(import('../pages/casino/games.vue' /* webpackChunkName: "pages/casino/games" */))
const _49e93e5b = () => interopDefault(import('../pages/casino/live-casino/index.vue' /* webpackChunkName: "pages/casino/live-casino/index" */))
const _02a9de73 = () => interopDefault(import('../pages/fantasybook/edit-stake.vue' /* webpackChunkName: "pages/fantasybook/edit-stake" */))
const _45a2436b = () => interopDefault(import('../pages/live-casino-old/live-casino.vue' /* webpackChunkName: "pages/live-casino-old/live-casino" */))
const _ad7336ce = () => interopDefault(import('../pages/markets/live-cards/index.vue' /* webpackChunkName: "pages/markets/live-cards/index" */))
const _47fb6cbf = () => interopDefault(import('../pages/markets/sportsbook/index.vue' /* webpackChunkName: "pages/markets/sportsbook/index" */))
const _70ee1b7d = () => interopDefault(import('../pages/profit-loss-new/detail.vue' /* webpackChunkName: "pages/profit-loss-new/detail" */))
const _07dc07b0 = () => interopDefault(import('../pages/profit-loss/detail.vue' /* webpackChunkName: "pages/profit-loss/detail" */))
const _8fc97440 = () => interopDefault(import('../pages/quick-signup/thankyou.vue' /* webpackChunkName: "pages/quick-signup/thankyou" */))
const _4249ef84 = () => interopDefault(import('../pages/wallet/deposit.vue' /* webpackChunkName: "pages/wallet/deposit" */))
const _6199542b = () => interopDefault(import('../pages/wallet/instant-withdrawal.vue' /* webpackChunkName: "pages/wallet/instant-withdrawal" */))
const _74c30682 = () => interopDefault(import('../pages/wallet/withdrawal.vue' /* webpackChunkName: "pages/wallet/withdrawal" */))
const _ec0d9552 = () => interopDefault(import('../pages/casino/casino-list/index-copy.vue' /* webpackChunkName: "pages/casino/casino-list/index-copy" */))
const _d45663a6 = () => interopDefault(import('../pages/markets/live-cards/32-card-casino.vue' /* webpackChunkName: "pages/markets/live-cards/32-card-casino" */))
const _69812210 = () => interopDefault(import('../pages/markets/live-cards/7up7down.vue' /* webpackChunkName: "pages/markets/live-cards/7up7down" */))
const _5d2fd1a4 = () => interopDefault(import('../pages/markets/live-cards/amar-akbar-anthony.vue' /* webpackChunkName: "pages/markets/live-cards/amar-akbar-anthony" */))
const _3c39f188 = () => interopDefault(import('../pages/markets/live-cards/andar-bahar.vue' /* webpackChunkName: "pages/markets/live-cards/andar-bahar" */))
const _0fab4b8e = () => interopDefault(import('../pages/markets/live-cards/dragon-tiger.vue' /* webpackChunkName: "pages/markets/live-cards/dragon-tiger" */))
const _7bf212e6 = () => interopDefault(import('../pages/markets/live-cards/live-teenpatti.vue' /* webpackChunkName: "pages/markets/live-cards/live-teenpatti" */))
const _de04a780 = () => interopDefault(import('../pages/markets/live-cards/poker.vue' /* webpackChunkName: "pages/markets/live-cards/poker" */))
const _26e229aa = () => interopDefault(import('../pages/markets/live-cards/t20-teenpatti.vue' /* webpackChunkName: "pages/markets/live-cards/t20-teenpatti" */))
const _eb8adaf2 = () => interopDefault(import('../pages/markets/live-cards/worli-matka.vue' /* webpackChunkName: "pages/markets/live-cards/worli-matka" */))
const _77dafc64 = () => interopDefault(import('../pages/wallet/product/deposit.vue' /* webpackChunkName: "pages/wallet/product/deposit" */))
const _6047c3df = () => interopDefault(import('../pages/wallet/product/withdrawal.vue' /* webpackChunkName: "pages/wallet/product/withdrawal" */))
const _2fb28d7e = () => interopDefault(import('../pages/markets/live-cards/_id.vue' /* webpackChunkName: "pages/markets/live-cards/_id" */))
const _4ea04406 = () => interopDefault(import('../pages/markets/live-casino/_id.vue' /* webpackChunkName: "pages/markets/live-casino/_id" */))
const _d6a8da32 = () => interopDefault(import('../pages/markets/sportsbook/_id.vue' /* webpackChunkName: "pages/markets/sportsbook/_id" */))
const _62385454 = () => interopDefault(import('../pages/fantasybook/inviteLink/_type/_joinContentCode/index.vue' /* webpackChunkName: "pages/fantasybook/inviteLink/_type/_joinContentCode/index" */))
const _7d8175a7 = () => interopDefault(import('../pages/fantasybook/match/_game/_matchType/index.vue' /* webpackChunkName: "pages/fantasybook/match/_game/_matchType/index" */))
const _9b12038c = () => interopDefault(import('../pages/casino/casino-list/_provider/_gameType/_subGameType/index.vue' /* webpackChunkName: "pages/casino/casino-list/_provider/_gameType/_subGameType/index" */))
const _74f7508e = () => interopDefault(import('../pages/fantasybook/match-pools/_betfairId/_game/_gameType/index.vue' /* webpackChunkName: "pages/fantasybook/match-pools/_betfairId/_game/_gameType/index" */))
const _065b27b6 = () => interopDefault(import('../pages/casino/casino-list/_provider/_gameType/_subGameType/_id/index.vue' /* webpackChunkName: "pages/casino/casino-list/_provider/_gameType/_subGameType/_id/index" */))
const _3e24108b = () => interopDefault(import('../pages/fantasybook/match/_game/_matchType/_betfairId/_gameType/index.vue' /* webpackChunkName: "pages/fantasybook/match/_game/_matchType/_betfairId/_gameType/index" */))
const _a13ece6e = () => interopDefault(import('../pages/fantasybook/choose-winner/_betfairId/_gameType/_contestName/_contestSize/_entryFees/index.vue' /* webpackChunkName: "pages/fantasybook/choose-winner/_betfairId/_gameType/_contestName/_contestSize/_entryFees/index" */))
const _238c289e = () => interopDefault(import('../pages/fantasybook/match-inner/_parentCategory/_betfairId/_game/_gameType/_contestId/index.vue' /* webpackChunkName: "pages/fantasybook/match-inner/_parentCategory/_betfairId/_game/_gameType/_contestId/index" */))
const _7b7f6dcc = () => interopDefault(import('../pages/fantasybook/users-leaderboard/_userId/_betfairId/_game/_status/_contestId/_playerId/index.vue' /* webpackChunkName: "pages/fantasybook/users-leaderboard/_userId/_betfairId/_game/_status/_contestId/_playerId/index" */))
const _33b98fa4 = () => interopDefault(import('../pages/fantasybook/users-leaderboard/_parentCategory/_userId/_betfairId/_game/_status/_contestId/_playerId/index.vue' /* webpackChunkName: "pages/fantasybook/users-leaderboard/_parentCategory/_userId/_betfairId/_game/_status/_contestId/_playerId/index" */))
const _3c5f19a2 = () => interopDefault(import('../pages/affiliate/_id/index.vue' /* webpackChunkName: "pages/affiliate/_id/index" */))
const _42a003f6 = () => interopDefault(import('../pages/casino-old/_id.vue' /* webpackChunkName: "pages/casino-old/_id" */))
const _79bfcb04 = () => interopDefault(import('../pages/casino-slots/_id.vue' /* webpackChunkName: "pages/casino-slots/_id" */))
const _7944002e = () => interopDefault(import('../pages/live-cards/_id.vue' /* webpackChunkName: "pages/live-cards/_id" */))
const _8b604dd4 = () => interopDefault(import('../pages/markets/_id.vue' /* webpackChunkName: "pages/markets/_id" */))
const _e2101816 = () => interopDefault(import('../pages/sportsbook/_category/index.vue' /* webpackChunkName: "pages/sportsbook/_category/index" */))
const _1083dc38 = () => interopDefault(import('../pages/wac/_id.vue' /* webpackChunkName: "pages/wac/_id" */))
const _ff7df578 = () => interopDefault(import('../pages/casino-old/_category/_id.vue' /* webpackChunkName: "pages/casino-old/_category/_id" */))
const _5492a5e8 = () => interopDefault(import('../pages/fantasybook/_betfairId/_gameType/index.vue' /* webpackChunkName: "pages/fantasybook/_betfairId/_gameType/index" */))
const _25c48a3d = () => interopDefault(import('../pages/live-casino-old/_category/_id.vue' /* webpackChunkName: "pages/live-casino-old/_category/_id" */))
const _21307028 = () => interopDefault(import('../pages/live-casino/_system/_pageNo/index.vue' /* webpackChunkName: "pages/live-casino/_system/_pageNo/index" */))
const _55636e02 = () => interopDefault(import('../pages/referalLink/_id/_name/index.vue' /* webpackChunkName: "pages/referalLink/_id/_name/index" */))
const _0461f24f = () => interopDefault(import('../pages/sportsbook/_category/_subCategory/index.vue' /* webpackChunkName: "pages/sportsbook/_category/_subCategory/index" */))
const _35f71a7e = () => interopDefault(import('../pages/promote/_name/_decryptPw/_countryCode/index.vue' /* webpackChunkName: "pages/promote/_name/_decryptPw/_countryCode/index" */))
const _54ca5d3f = () => interopDefault(import('../pages/casino-old/_pn/_lang/_provider/live-casino.vue' /* webpackChunkName: "pages/casino-old/_pn/_lang/_provider/live-casino" */))
const _636881d1 = () => interopDefault(import('../pages/casino-old/_pn/_lang/_provider/_tableId.vue' /* webpackChunkName: "pages/casino-old/_pn/_lang/_provider/_tableId" */))
const _0ccf5348 = () => interopDefault(import('../pages/sportsbook/_category/_subCategory/_id/_betradar.vue' /* webpackChunkName: "pages/sportsbook/_category/_subCategory/_id/_betradar" */))
const _482400f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _29f09dda,
    name: "about-us"
  }, {
    path: "/affiliate-partner",
    component: _504f09fc,
    name: "affiliate-partner"
  }, {
    path: "/aml-policies",
    component: _1ebe6a39,
    name: "aml-policies"
  }, {
    path: "/bets",
    component: _3931f204,
    name: "bets"
  }, {
    path: "/blockuserip",
    component: _5b8f679a,
    name: "blockuserip"
  }, {
    path: "/blog-old",
    component: _aae7f910,
    name: "blog-old"
  }, {
    path: "/book-register",
    component: _688cf58a,
    name: "book-register"
  }, {
    path: "/casino",
    component: _2e1f9f98,
    name: "casino"
  }, {
    path: "/casino-old",
    component: _4a97d60e,
    name: "casino-old"
  }, {
    path: "/casino-slots",
    component: _38e2119c,
    name: "casino-slots"
  }, {
    path: "/change-mobileno",
    component: _69a27fd8,
    name: "change-mobileno"
  }, {
    path: "/change-password",
    component: _4f3260cc,
    name: "change-password"
  }, {
    path: "/check-in",
    component: _0356af06,
    name: "check-in"
  }, {
    path: "/contact-us",
    component: _731198e7,
    name: "contact-us"
  }, {
    path: "/dglp",
    component: _144c3406,
    name: "dglp"
  }, {
    path: "/enroll-now",
    component: _6db436aa,
    name: "enroll-now"
  }, {
    path: "/fantasy-pro-rules",
    component: _260591a4,
    name: "fantasy-pro-rules"
  }, {
    path: "/fantasybook",
    component: _78462af4,
    name: "fantasybook"
  }, {
    path: "/faqs",
    component: _ab9778ce,
    name: "faqs"
  }, {
    path: "/fast-signup",
    component: _596c5ea0,
    name: "fast-signup"
  }, {
    path: "/favorites",
    component: _16afeeea,
    name: "favorites"
  }, {
    path: "/finsa",
    component: _74e45790,
    name: "finsa"
  }, {
    path: "/forgot-password",
    component: _53538439,
    name: "forgot-password"
  }, {
    path: "/gift-card",
    component: _cf5692f8,
    name: "gift-card"
  }, {
    path: "/inplay",
    component: _61273b56,
    name: "inplay"
  }, {
    path: "/line-market-rules",
    component: _636c58da,
    name: "line-market-rules"
  }, {
    path: "/live-cards",
    component: _d872bd7e,
    name: "live-cards"
  }, {
    path: "/live-casino",
    component: _01a0a2ad,
    name: "live-casino"
  }, {
    path: "/live-games",
    component: _5a56cf1f,
    name: "live-games"
  }, {
    path: "/login",
    component: _b4d4bac6,
    name: "login"
  }, {
    path: "/loyalty",
    component: _5ba22c0c,
    name: "loyalty"
  }, {
    path: "/maintenance",
    component: _4bd57c47,
    name: "maintenance"
  }, {
    path: "/my-markets",
    component: _2a3de492,
    name: "my-markets"
  }, {
    path: "/new-pending-bonus",
    component: _74199ab0,
    name: "new-pending-bonus"
  }, {
    path: "/new-pending-sportbonus",
    component: _4be17a6a,
    name: "new-pending-sportbonus"
  }, {
    path: "/news",
    component: _71d8a962,
    name: "news"
  }, {
    path: "/notifications",
    component: _4a8ed6c8,
    name: "notifications"
  }, {
    path: "/online-betting-app1",
    component: _1f6c57a8,
    name: "online-betting-app1"
  }, {
    path: "/pending-bonus",
    component: _afe770c6,
    name: "pending-bonus"
  }, {
    path: "/playnow",
    component: _fe818a54,
    name: "playnow"
  }, {
    path: "/premium-bookmaker-fancy",
    component: _13830a35,
    name: "premium-bookmaker-fancy"
  }, {
    path: "/premium-cricket-rules",
    component: _f8abbf4e,
    name: "premium-cricket-rules"
  }, {
    path: "/premium-sportsbook",
    component: _59e8e9c6,
    name: "premium-sportsbook"
  }, {
    path: "/premium-sportsbook-rules",
    component: _c217a2f8,
    name: "premium-sportsbook-rules"
  }, {
    path: "/privacy-policy",
    component: _dc6e56da,
    name: "privacy-policy"
  }, {
    path: "/profile",
    component: _dc377446,
    name: "profile"
  }, {
    path: "/profit-loss",
    component: _03991043,
    name: "profit-loss"
  }, {
    path: "/profit-loss-new",
    component: _0f3eced6,
    name: "profit-loss-new"
  }, {
    path: "/promotions",
    component: _019aa72c,
    name: "promotions"
  }, {
    path: "/quick-checkin",
    component: _7bbe6181,
    name: "quick-checkin"
  }, {
    path: "/quick-enroll",
    component: _8464a8fc,
    name: "quick-enroll"
  }, {
    path: "/quick-joinus1",
    component: _2273333d,
    name: "quick-joinus1"
  }, {
    path: "/quick-joinus2",
    component: _22814abe,
    name: "quick-joinus2"
  }, {
    path: "/quick-reg",
    component: _0bfe8730,
    name: "quick-reg"
  }, {
    path: "/quick-reg2",
    component: _462e79fa,
    name: "quick-reg2"
  }, {
    path: "/quick-signup",
    component: _965ffb12,
    name: "quick-signup"
  }, {
    path: "/quick-signup-2",
    component: _65d48719,
    name: "quick-signup-2"
  }, {
    path: "/quick-signup1",
    component: _771d6d6d,
    name: "quick-signup1"
  }, {
    path: "/quick-signup2",
    component: _772b84ee,
    name: "quick-signup2"
  }, {
    path: "/quick-signup3",
    component: _77399c6f,
    name: "quick-signup3"
  }, {
    path: "/quick-signup4",
    component: _7747b3f0,
    name: "quick-signup4"
  }, {
    path: "/racing",
    component: _5b7a004d,
    name: "racing"
  }, {
    path: "/referral",
    component: _3f5255ce,
    name: "referral"
  }, {
    path: "/region-block",
    component: _403e8720,
    name: "region-block"
  }, {
    path: "/register",
    component: _02f0d5c2,
    name: "register"
  }, {
    path: "/registration",
    component: _5459fb96,
    name: "registration"
  }, {
    path: "/registration2",
    component: _36b62466,
    name: "registration2"
  }, {
    path: "/registration3",
    component: _3699f564,
    name: "registration3"
  }, {
    path: "/reset-password",
    component: _41e629d5,
    name: "reset-password"
  }, {
    path: "/responsible-gambling",
    component: _44a38958,
    name: "responsible-gambling"
  }, {
    path: "/rules-regulations",
    component: _34479e67,
    name: "rules-regulations"
  }, {
    path: "/settings",
    component: _f7a93ac2,
    name: "settings"
  }, {
    path: "/sitemap",
    component: _0e3dfa2e,
    name: "sitemap"
  }, {
    path: "/sportsbook",
    component: _327ba967,
    name: "sportsbook"
  }, {
    path: "/store-profit-loss",
    component: _4f707952,
    name: "store-profit-loss"
  }, {
    path: "/survey",
    component: _4fbde356,
    name: "survey"
  }, {
    path: "/termsandconditions",
    component: _45b828a4,
    name: "termsandconditions"
  }, {
    path: "/thankyou",
    component: _5a320b1e,
    name: "thankyou"
  }, {
    path: "/time-settings",
    component: _960ba552,
    name: "time-settings"
  }, {
    path: "/transactions",
    component: _20e2165e,
    name: "transactions"
  }, {
    path: "/transfer-statements",
    component: _1ec423ba,
    name: "transfer-statements"
  }, {
    path: "/transfer-statements-new",
    component: _2a2900e6,
    name: "transfer-statements-new"
  }, {
    path: "/tutorials",
    component: _e2751fee,
    name: "tutorials"
  }, {
    path: "/user-book",
    component: _a5f42842,
    name: "user-book"
  }, {
    path: "/user-registration",
    component: _816318a2,
    name: "user-registration"
  }, {
    path: "/wac",
    component: _2ee6a5d0,
    name: "wac"
  }, {
    path: "/wallet",
    component: _6bf27990,
    name: "wallet"
  }, {
    path: "/casino-old/live-casino",
    component: _113fd432,
    name: "casino-old-live-casino"
  }, {
    path: "/casino/casino-list",
    component: _17f09c4a,
    name: "casino-casino-list"
  }, {
    path: "/casino/games",
    component: _55c9aaa3,
    name: "casino-games"
  }, {
    path: "/casino/live-casino",
    component: _49e93e5b,
    name: "casino-live-casino"
  }, {
    path: "/fantasybook/edit-stake",
    component: _02a9de73,
    name: "fantasybook-edit-stake"
  }, {
    path: "/live-casino-old/live-casino",
    component: _45a2436b,
    name: "live-casino-old-live-casino"
  }, {
    path: "/markets/live-cards",
    component: _ad7336ce,
    name: "markets-live-cards"
  }, {
    path: "/markets/sportsbook",
    component: _47fb6cbf,
    name: "markets-sportsbook"
  }, {
    path: "/profit-loss-new/detail",
    component: _70ee1b7d,
    name: "profit-loss-new-detail"
  }, {
    path: "/profit-loss/detail",
    component: _07dc07b0,
    name: "profit-loss-detail"
  }, {
    path: "/quick-signup/thankyou",
    component: _8fc97440,
    name: "quick-signup-thankyou"
  }, {
    path: "/wallet/deposit",
    component: _4249ef84,
    name: "wallet-deposit"
  }, {
    path: "/wallet/instant-withdrawal",
    component: _6199542b,
    name: "wallet-instant-withdrawal"
  }, {
    path: "/wallet/withdrawal",
    component: _74c30682,
    name: "wallet-withdrawal"
  }, {
    path: "/casino/casino-list/index-copy",
    component: _ec0d9552,
    name: "casino-casino-list-index-copy"
  }, {
    path: "/markets/live-cards/32-card-casino",
    component: _d45663a6,
    name: "markets-live-cards-32-card-casino"
  }, {
    path: "/markets/live-cards/7up7down",
    component: _69812210,
    name: "markets-live-cards-7up7down"
  }, {
    path: "/markets/live-cards/amar-akbar-anthony",
    component: _5d2fd1a4,
    name: "markets-live-cards-amar-akbar-anthony"
  }, {
    path: "/markets/live-cards/andar-bahar",
    component: _3c39f188,
    name: "markets-live-cards-andar-bahar"
  }, {
    path: "/markets/live-cards/dragon-tiger",
    component: _0fab4b8e,
    name: "markets-live-cards-dragon-tiger"
  }, {
    path: "/markets/live-cards/live-teenpatti",
    component: _7bf212e6,
    name: "markets-live-cards-live-teenpatti"
  }, {
    path: "/markets/live-cards/poker",
    component: _de04a780,
    name: "markets-live-cards-poker"
  }, {
    path: "/markets/live-cards/t20-teenpatti",
    component: _26e229aa,
    name: "markets-live-cards-t20-teenpatti"
  }, {
    path: "/markets/live-cards/worli-matka",
    component: _eb8adaf2,
    name: "markets-live-cards-worli-matka"
  }, {
    path: "/wallet/product/deposit",
    component: _77dafc64,
    name: "wallet-product-deposit"
  }, {
    path: "/wallet/product/withdrawal",
    component: _6047c3df,
    name: "wallet-product-withdrawal"
  }, {
    path: "/markets/live-cards/:id?",
    component: _2fb28d7e,
    name: "markets-live-cards-id"
  }, {
    path: "/markets/live-casino/:id?",
    component: _4ea04406,
    name: "markets-live-casino-id"
  }, {
    path: "/markets/sportsbook/:id",
    component: _d6a8da32,
    name: "markets-sportsbook-id"
  }, {
    path: "/fantasybook/inviteLink/:type?/:joinContentCode",
    component: _62385454,
    name: "fantasybook-inviteLink-type-joinContentCode"
  }, {
    path: "/fantasybook/match/:game?/:matchType",
    component: _7d8175a7,
    name: "fantasybook-match-game-matchType"
  }, {
    path: "/casino/casino-list/:provider?/:gameType/:subGameType",
    component: _9b12038c,
    name: "casino-casino-list-provider-gameType-subGameType"
  }, {
    path: "/fantasybook/match-pools/:betfairId?/:game?/:gameType",
    component: _74f7508e,
    name: "fantasybook-match-pools-betfairId-game-gameType"
  }, {
    path: "/casino/casino-list/:provider?/:gameType/:subGameType?/:id",
    component: _065b27b6,
    name: "casino-casino-list-provider-gameType-subGameType-id"
  }, {
    path: "/fantasybook/match/:game?/:matchType?/:betfairId/:gameType",
    component: _3e24108b,
    name: "fantasybook-match-game-matchType-betfairId-gameType"
  }, {
    path: "/fantasybook/choose-winner/:betfairId?/:gameType?/:contestName?/:contestSize?/:entryFees",
    component: _a13ece6e,
    name: "fantasybook-choose-winner-betfairId-gameType-contestName-contestSize-entryFees"
  }, {
    path: "/fantasybook/match-inner/:parentCategory?/:betfairId?/:game?/:gameType?/:contestId",
    component: _238c289e,
    name: "fantasybook-match-inner-parentCategory-betfairId-game-gameType-contestId"
  }, {
    path: "/fantasybook/users-leaderboard/:userId?/:betfairId?/:game?/:status?/:contestId?/:playerId",
    component: _7b7f6dcc,
    name: "fantasybook-users-leaderboard-userId-betfairId-game-status-contestId-playerId"
  }, {
    path: "/fantasybook/users-leaderboard/:parentCategory?/:userId?/:betfairId?/:game?/:status?/:contestId?/:playerId",
    component: _33b98fa4,
    name: "fantasybook-users-leaderboard-parentCategory-userId-betfairId-game-status-contestId-playerId"
  }, {
    path: "/affiliate/:id",
    component: _3c5f19a2,
    name: "affiliate-id"
  }, {
    path: "/casino-old/:id",
    component: _42a003f6,
    name: "casino-old-id"
  }, {
    path: "/casino-slots/:id",
    component: _79bfcb04,
    name: "casino-slots-id"
  }, {
    path: "/live-cards/:id?",
    component: _7944002e,
    name: "live-cards-id"
  }, {
    path: "/markets/:id?",
    component: _8b604dd4,
    name: "markets-id"
  }, {
    path: "/sportsbook/:category",
    component: _e2101816,
    name: "sportsbook-category"
  }, {
    path: "/wac/:id",
    component: _1083dc38,
    name: "wac-id"
  }, {
    path: "/casino-old/:category/:id",
    component: _ff7df578,
    name: "casino-old-category-id"
  }, {
    path: "/fantasybook/:betfairId/:gameType",
    component: _5492a5e8,
    name: "fantasybook-betfairId-gameType"
  }, {
    path: "/live-casino-old/:category?/:id",
    component: _25c48a3d,
    name: "live-casino-old-category-id"
  }, {
    path: "/live-casino/:system?/:pageNo",
    component: _21307028,
    name: "live-casino-system-pageNo"
  }, {
    path: "/referalLink/:id?/:name",
    component: _55636e02,
    name: "referalLink-id-name"
  }, {
    path: "/sportsbook/:category/:subCategory",
    component: _0461f24f,
    name: "sportsbook-category-subCategory"
  }, {
    path: "/promote/:name?/:decryptPw?/:countryCode",
    component: _35f71a7e,
    name: "promote-name-decryptPw-countryCode"
  }, {
    path: "/casino-old/:pn/:lang/:provider?/live-casino",
    component: _54ca5d3f,
    name: "casino-old-pn-lang-provider-live-casino"
  }, {
    path: "/casino-old/:pn/:lang/:provider?/:tableId?",
    component: _636881d1,
    name: "casino-old-pn-lang-provider-tableId"
  }, {
    path: "/sportsbook/:category/:subCategory/:id/:betradar?",
    component: _0ccf5348,
    name: "sportsbook-category-subCategory-id-betradar"
  }, {
    path: "/",
    component: _482400f4,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
